import React from 'react';
import { Heading, Box, Center, useDisclosure, Button } from '@chakra-ui/react';

import useInvitation from '@hooks/useInvitation';

import WithAnimation from '@components/Common/WithAnimation';
import AddToCalendarButton from '@components/Common/AddToCalendar';
import CountDown from '@components/Common/CountDown';
import QRSection from '@/components/Common/QRCard/V2';
import WeddingDetail from './WeddingDetail';
import BaliGuestInformation from './BaliGuestInformation';

import {
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_RESEPSI_FULLDATE,
  GOOGLE_MAPS_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
} from '@/constants';
import { HEADING_STYLES, LINK_STYLES } from '@constants/colors';
import { ID_COUNTDOWN_SECTION } from '@constants/identifier';
import * as ID from '@/constants/identifier';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import { MUSLIM_INVITATION } from '@constants/feature-flags';
import useShiftTime from '@hooks/useShiftTime';

function WeddingSection({ ...rest }) {
  const isInvitation = useInvitation();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const lang = useLang();
  const time = useShiftTime();

  return (
    <>
      <Box padding="12px 0 0px 0" bgColor="bgPrimary" {...rest}>
        <WithAnimation>
          <Heading marginBottom="42px" {...HEADING_STYLES} paddingLeft="0px" textAlign="center">
            {txtWording.title[lang]}
          </Heading>
        </WithAnimation>
        {/* Wedding info section */}
        <Box padding="0 28px" marginTop="-16px">
          <WithAnimation>
            <WeddingDetail
              date={WEDDING_AKAD_FULLDATE}
              time={WEDDING_AKAD_TIME}
              title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
              subTitle={
                isInvitation ? (
                  <>
                    <span>{WEDDING_AKAD_LOC_NAME}</span>
                    <br />
                    <span>
                      <i>{WEDDING_AKAD_LOC_ROAD}</i>
                    </span>
                  </>
                ) : (
                  <></>
                )
              }
              location={isInvitation ? "https://maps.app.goo.gl/5fpvD1bwkQw62bZm9?g_st=iw" : ""}
            />
          </WithAnimation>
          {isInvitation && (
            <WithAnimation>
              <WeddingDetail
                date={WEDDING_RESEPSI_FULLDATE}
                time="11.00 WIB"
                title="TEA CEREMONY GROOM SIDE"
                subTitle={
                  <>
                    <span>Regale Restaurant Level 2</span>
                    <br />
                    <span>
                      <i>H.Adam Malik Street No.66-68, Medan </i>
                    </span>
                  </>
                }
                location="https://maps.app.goo.gl/DLg9yfAFnGXEXYfU7?g_st=iw"
              />
            </WithAnimation>
          )}
          {isInvitation && (
            <WithAnimation>
              <WeddingDetail
                date={WEDDING_RESEPSI_FULLDATE}
                time="13.00 WIB"
                title="TEA CEREMONY BRIDE SIDE"
                subTitle={
                  <>
                    <span>{`Grand City Hall - D'Heritage`}</span>
                    <br />
                    <span>
                      <i>Balai Kota Street No.01, Medan </i>
                    </span>
                  </>
                }
                location="https://maps.app.goo.gl/p54fbfbxofCRY9g36?g_st=iw"
              />
            </WithAnimation>
          )}
          {isInvitation && (
            <WithAnimation>
              <WeddingDetail
                date={WEDDING_RESEPSI_FULLDATE}
                time={time}
                title={txtWording.reception[lang]}
                subTitle={
                  <>
                    <span>{WEDDING_RESEPSI_LOC_NAME}</span>
                    <br />
                    <span>
                      <i>{WEDDING_RESEPSI_LOC_ROAD}</i>
                    </span>
                  </>
                }
                location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )}
          {false && (
            <WithAnimation>
              <Button {...LINK_STYLES} variant="ghost" padding="0" onClick={onOpen}>
                Bali Guest Information
              </Button>
            </WithAnimation>
          )}
          {isInvitation && (
          <WithAnimation>
            <Center>
              <QRSection name={ID.ID_QRCODE} />
            </Center>
          </WithAnimation>
        )}
        </Box>
        <WithAnimation>
          <Heading
            {...HEADING_STYLES}
            textAlign="center"
            paddingLeft="0px"
            margin="52px 0 26px 0"
            name={ID_COUNTDOWN_SECTION}
          >
            COUNTDOWN
          </Heading>
        </WithAnimation>

        <Box width="100%" bgColor="bgSecondary" padding="12px 0 32px 0" color="secondaryColorText">
          <WithAnimation>
            <Center>
              <CountDown lang="en" />
            </Center>
          </WithAnimation>
          {isInvitation && (
            <WithAnimation>
              <Center>
                <AddToCalendarButton
                  marginTop="16px"
                  size="sm"
                  text={txtWording.calendar[lang]}
                  variant="ghost"
                  fontSize="md"
                  colorScheme="blackAlpha"
                  color="mainColorTextLight"
                  bgColor="bgAlternative"
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
      {isInvitation && <BaliGuestInformation lang={'en'} onClose={onClose} visible={isOpen} />}
    </>
  );
}

export default React.memo(WeddingSection);
